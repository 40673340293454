import i18n from './i18n'
import _ from 'lodash'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      connectionsInstance: undefined
    }
  },
  beforeCreate () {
    this.$servicesPLK.translation.init(i18n, { overwrite: false })
  },
  methods: {
    haveRelation (actions, relation) {
      return actions.find(action => action.params.rel === relation)
    },
    async downloadFile (href, name) {
      this.executeAction({ href }, false, (content) => {
        saveAs(new File([typeof content === 'object' ? JSON.stringify(content, null, 2) : content], name, { type: 'text/plain' }))
      })
    },
    executeAction ({ params = {}, href }, showModal = true, handlerFunction, handlerErrorFunction) {
      const actionExecution = async () => {
        try {
          const content = await this.$servicesPLK.cms.executeAction(params.type, href)
          if (handlerFunction) {
            handlerFunction(content)
          }
        } catch (e) {
          console.error(e)
          if (handlerErrorFunction) {
            handlerErrorFunction(e)
          }
        }
      }
      if (showModal) {
        this.$modal.show('dialog', {
          title: this.$t(`designer.cms.dialog.${params.rel}.title`),
          text: this.$t(`designer.cms.dialog.${params.rel}.text`),
          buttons: [
            {
              title: this.$t('designer.cms.dialog.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.cms.dialog.confirm'),
              handler: async () => {
                actionExecution()
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      } else {
        actionExecution()
      }
    },
    async getElementsConnection (folder = 'ROOT') {
      const { resource } = await this.$servicesPLK.cms.getElementsConnectionByFolder(folder)
      return resource
    },
    async getFolders (filter) {
      const loadFoldersByConnection = async (lastPage = 0) => {
        const { resource } = await this.$servicesPLK.cms.getFolders(lastPage, filter)
        let _files = _.cloneDeep(resource)
        if (resource.length > 0) {
          _files.concat(await loadFoldersByConnection(lastPage + 1))
        }
        return _files
      }
      const elements = await loadFoldersByConnection()
      return elements
    },
    async getStatistics (folderAbstraction, abstraction) {
      const data = await this.$servicesPLK.cms.getStatistics(folderAbstraction, abstraction)
      return data
    },
    async getElementsFile (folder, filter) {
      const loadElementsByConnection = async (lastPage = 0) => {
        const { resource } = await this.$servicesPLK.cms.getElementsFileByFolder(folder, lastPage, filter)
        let _files = _.cloneDeep(resource)
        if (resource.length > 0) {
          _files.concat(await loadElementsByConnection(lastPage + 1))
        }
        return _files
      }
      const elements = await loadElementsByConnection()
      return elements
    }
  }
}
