<template>
  <div class="plk_dblist">
    <Menu></Menu>
    <div class="viewer">
      <div class="list">
        <h5>{{$t('designer.cms.db.list.title')}}</h5>
        <!-- Folder -->
        <FolderList class="doce columns" @onChange="updateFolder"/>
        <div class="table-overflow">
          <table class="u-full-width">
            <thead>
            <tr>
              <th>{{$t('designer.cms.db.list.bdname')}}</th>
              <th colspan=3></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(db, key) in dbs" :key="key">
                <td>
                  {{db.resource.name}}
                </td>
                <td v-if="haveRelation(db.action, 'update')" @click="updateConnection(db.resource.idFolder, db.resource.id)" class="pointer">
                  <i :title="$t('designer.cms.db.list.edit')" class="fas fa-edit"></i>
                </td>
                <td v-if="haveRelation(db.action, 'delete')" @click="executeAction(haveRelation(db.action, 'delete'), true, loadElementsConnections)" class="pointer">
                  <i :title="$t('designer.cms.db.list.delete')" class="fas fa-trash"></i>
                </td>
                <td v-if="haveRelation(db.action, 'test')" @click="executeAction(haveRelation(db.action, 'test'), false, onFinishOKTest)" class="pointer">
                  <i :title="$t('designer.cms.db.list.test')" class="fas fa-ethernet"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="createDatabase()" class="w-100">{{$t('designer.cms.db.list.createdb')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from './i18n'
import FolderList from '../components/FolderList'
import Menu from '../../components/menus/Menu'
import mixincms from '@/mixins/cms'
import { VIEWS } from '@/router/design'

export default {
  name: 'dblist',
  components: {
    Menu,
    FolderList
  },
  mixins: [mixincms],
  data () {
    return {
      folderConnections: '',
      dbs: []
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadElementsConnections()
    },
    updateConnection (folder, connection) {
      this.$router.push({
        name: VIEWS.CMS.DATABASE_UPDATE.name,
        query: { folder, connection }
      })
    },
    createDatabase () {
      this.$router.push({
        name: VIEWS.CMS.DATABASE_CREATOR.name,
        query: { folder: this.folderConnections }
      })
    },
    async loadElementsConnections () {
      this.dbs = await this.getElementsConnection(this.folderConnections)
    },
    onFinishOKTest (response) {
      this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'success', msg: 'designer.cms.db.list.connectionOK' })
    },
    updateFolder (folder) {
      this.folderConnections = folder
    }
  },
  watch: {
    folderConnections () {
      this.loadElementsConnections()
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_dblist {
    height: 100vh;

    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);

      tr.btn:hover {
        background: #efefef;
      }
    }
  }
</style>
