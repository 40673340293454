<template>
  <div>
    <label :for="id">{{$t(title)}}</label>
    <select required :id="id" v-model="selectedFolder" class="u-full-width">
        <option :value="folder.resource.id" v-for="folder in folders" :key="folder.resource.id">{{folder.resource.name}}</option>
    </select>
  </div>
</template>
<script>
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import i18n from './i18n'
import mixincms from '@/mixins/cms'

export default {
  name: 'FolderList',
  mixins: [mixincms],
  props: {
    initial: {
      required: false,
      default: ''
    },
    title: {
      required: false,
      default: 'designer.cms.folder'
    },
    cancreate: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: uuidv4(),
      folders: [],
      selectedFolder: this.initial || this.$route.query.folder || 'ROOT',
      dbs: []
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadFolders()
      this.$emit('onChange', this.selectedFolder)
    },
    async loadFolders () {
      let folders = (await this.getFolders())
      if (this.cancreate) {
        folders = folders.filter(f => this.haveRelation(f.action, 'create'))
      }
      Vue.set(this, 'folders', folders)
    }
  },
  watch: {
    selectedFolder () {
      this.$emit('onChange', this.selectedFolder)
    },
    initial () {
      if (this.initValue) {
        this.$emit(this, 'selectedFolder', this.initial)
      }
    }
  }
}
</script>
